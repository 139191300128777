export const locales = ["it", "en", "es", "pt", "fr"] as const

export type Locale = typeof locales[number]

export const slugs: Record<Locale, string> = {
  it: "offerta-gratuita",
  en: "get-free-offer",
  fr: "estimation-gratuite",
  pt: "avaliacao-casa",
  es: "valoracion-vivienda",
}
