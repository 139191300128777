import type { AddressAutocompletePageContentProps } from "@app/components/Address/AddressAutocompletePageContent"
import { ContentContainer } from "@app/components/ContentContainer"
import { Layout } from "@app/components/Layout"
import { Loader } from "@app/components/Loader"
import { useAddress } from "@app/contexts/address"
import useCurrentStep from "@app/hooks/useCurrentStep"
import useTracking from "@app/hooks/useTracking"
import { isAddressComplete } from "@app/lib/addressComponent"
import { isAppRedirect, storeIsAppRedirect } from "@app/lib/casavoVisitsUtils/appRedirectUtils"
import { isInAppWebView, storeIsInAppWebView } from "@app/lib/casavoVisitsUtils/appWebViewUtils"
import { useDependency } from "@app/lib/ioc/useDependency"
import { useSaveMarketingCampaignParams } from "@app/lib/marketingServerToServer"
import {
  autocompleteLoadingTime,
  clearSTSSentEvents,
  generateAndStoreValuationSessionId,
  getValuationSessionId,
  hasExpressedMeasurementTrackingPreference,
  hasGivenMeasurementTrackingConsent,
  trackAppWebViewEvent,
  trackUserInteraction,
} from "@app/lib/tracking"
import { compilePath } from "@app/lib/urlPath"
import { useSaveReferralParams } from "@app/lib/useSaveReferralParams"
import { waitFor } from "@app/lib/waitFor"
import { resetStore } from "@app/store/global"
import { Locale, locales, slugs } from "@app/types/locale"
import { NextPage } from "next"
import useTranslation from "next-translate/useTranslation"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useRouter } from "next/router"
import qs from "querystring"
import { FC, useEffect } from "react"
import { useDispatch } from "react-redux"
import url from "url"

import { goToHeyFlow, shouldGoToHeyFlow } from "@app/lib/heyflow/utils"

import { emitSnowplowEvent, eventSchema } from "@app/lib/tracking/snowplow"
import { storeTrafficSource } from "@app/lib/trafficSourceStorage"
import { storeUnicreditMarketingPolicy } from "@app/lib/unicreditMarketingPolicyStorage"

const productionUrlByLocale = (locale: Locale): string =>
  `${process.env.NEXT_PUBLIC_FLOW_URL}/${locale}/${slugs[locale]}/1/1/`

const Seo: FC<{ locale: Locale }> = ({ locale }) => (
  <Head>
    <link rel="canonical" href={productionUrlByLocale(locale)} />
    {locales.map((l, index) => (
      <link key={index} rel="alternate" hrefLang={l} href={productionUrlByLocale(l)} />
    ))}
  </Head>
)

const AddressAutocompletePageContent = dynamic<AddressAutocompletePageContentProps>(
  import("@app/components/Address/AddressAutocompletePageContent"),
  {
    ssr: false,
  }
)

type Props = {
  generatedOnServer: boolean
}

type AddressField = {
  city: string | string[] | undefined
  country: string | string[] | undefined
  lat: string | string[] | undefined
  location: string | string[] | undefined
  lon: string | string[] | undefined
  street: string | string[] | undefined
  streetNumber: string | string[] | undefined
  zipCode: string | string[] | undefined
  province: string | string[] | undefined
  provinceInitials: string | string[] | undefined
}

function extractAddressFields(urlString: string): AddressField {
  const parsedPath = url.parse(urlString)
  const {
    city,
    country,
    lat,
    location,
    lon,
    street,
    streetNumber = "",
    zipCode,
    province,
    provinceInitials,
  } = qs.parse(parsedPath.query || "")
  return {
    city: city,
    lat: lat,
    lon: lon,
    location: location,
    country: country,
    street: street,
    streetNumber: streetNumber,
    zipCode: zipCode,
    province: province,
    provinceInitials: provinceInitials,
  }
}

function removeAllParams(urlString: string, whiteList: ReadonlyArray<string> = []): string {
  const parsedUrl = url.parse(urlString, true)
  const params = parsedUrl.query
  const fieldsToDelete = Object.keys(params)
  fieldsToDelete.forEach((field) => {
    if (!whiteList.includes(field)) {
      delete params[field]
    }
  })
  return url.format({ pathname: parsedUrl.pathname, query: params })
}

const APP_WEBVIEW_QUERY_PARAM_VALUE = "app_webview"

const AddressAutocompletePage: NextPage<Props> = ({ generatedOnServer }) => {
  const { pushToDataLayer } = useTracking()
  const [_, currentSubStep] = useCurrentStep()
  const dispatch = useDispatch()
  const [_address, setAddress] = useAddress()
  const router = useRouter()
  const { lang } = useTranslation()

  if (generatedOnServer) {
    autocompleteLoadingTime.setTrackingEnabled()
  }

  useEffect(() => {
    if (generatedOnServer) {
      dispatch(resetStore())
    }
  }, [generatedOnServer, dispatch])

  const trackServerEvent = useDependency("trackServerEvent")
  const trafficSource = router.query.traffic_source
  const unicreditMarketingPolicy = router.query.claw_uci_enable

  const isAppWebView = trafficSource === APP_WEBVIEW_QUERY_PARAM_VALUE || isInAppWebView()
  const isFromAppRedirect = trafficSource === "casavo_visits_redirect" || isAppRedirect()

  const isAppIncomingUser = isAppWebView || isFromAppRedirect

  useEffect(() => {
    if (typeof window !== "undefined") {
      generateAndStoreValuationSessionId()
      clearSTSSentEvents()

      waitFor(hasExpressedMeasurementTrackingPreference).then(() => {
        isAppWebView && trackAppWebViewEvent("ValuationStarted")
        trackServerEvent("valuationFlowStart", {
          data: {
            // example: "homepage" (wine) | "sell_house" (wine) | "app_webview" | undefined
            trafficSource: isAppIncomingUser ? APP_WEBVIEW_QUERY_PARAM_VALUE : (trafficSource as string),
            language: lang as Locale,
          },
        })

        emitSnowplowEvent({
          schema: eventSchema.valuationFlowStart,
          data: {
            valuationSessionId: hasGivenMeasurementTrackingConsent() ? getValuationSessionId() : null,
            trafficSource: isAppIncomingUser ? APP_WEBVIEW_QUERY_PARAM_VALUE : (trafficSource as string),
            language: lang as Locale,
          },
        })
      })

      trackUserInteraction("SellFLow", "Start", "Flow", { appWebView: isAppWebView })
      storeIsInAppWebView(isAppWebView)
      storeIsAppRedirect(isFromAppRedirect)
      storeTrafficSource(trafficSource)
      storeUnicreditMarketingPolicy(unicreditMarketingPolicy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSaveMarketingCampaignParams()
  useSaveReferralParams()

  const hasLocationParameter = () => {
    const queryParams = qs.parse(url.parse(router.asPath).query || "")
    return !router.query.slug || (queryParams["location"] !== undefined && queryParams["location"] !== "")
  }

  useEffect(() => {
    if (!router.query.slug) {
      // if slug is not in query, next is returning the path containing brackets
      return
    }
    const rawAddress = extractAddressFields(router.asPath)

    const address = {
      city: rawAddress.city as string,
      coordinates: {
        latitude: Number(rawAddress.lat),
        longitude: Number(rawAddress.lon),
      },
      country: rawAddress.country as string,
      countryCode: rawAddress.country as string,
      street: rawAddress.street as string,
      number: rawAddress.streetNumber as string,
      zipCode: rawAddress.zipCode as string,
      province: rawAddress.province as string,
      provinceInitials: rawAddress.provinceInitials as string,
    }

    const fillAtLeastOneLocationDetail =
      rawAddress.city ||
      rawAddress.country ||
      rawAddress.lat ||
      rawAddress.lon ||
      rawAddress.street ||
      rawAddress.streetNumber ||
      rawAddress.zipCode

    if (fillAtLeastOneLocationDetail) {
      setAddress(address)
      if (isAddressComplete(address)) {
        trackServerEvent("flowStartRedirect", { data: { addressComplete: true, source: "addressInUrl" } })
        emitSnowplowEvent({
          schema: eventSchema.flowStartRedirect,
          data: { addressComplete: true, source: "addressInUrl" },
        })
        // this cleans the path from the address, we only have to do it if we skip 1/2
        const cleanPathname = removeAllParams(router.asPath, ["traffic_source", "ucid", "gclid"])

        if (shouldGoToHeyFlow(lang as Locale)) {
          goToHeyFlow(address)
        } else {
          router.push(compilePath(cleanPathname, { step: 2, substep: 1 }))
        }
      } else {
        trackServerEvent("flowStartRedirect", { data: { addressComplete: false, source: "addressInUrl" } })
        emitSnowplowEvent({
          schema: eventSchema.flowStartRedirect,
          data: { addressComplete: false, source: "addressInUrl" },
        })
        router.push(compilePath(router.asPath, { substep: currentSubStep + 1 }))
      }
    } else if (rawAddress.location) {
      trackServerEvent("flowStartRedirect", { data: { source: "locationInUrl" } })
      emitSnowplowEvent({ schema: eventSchema.flowStartRedirect, data: { source: "locationInUrl" } })
      router.push(compilePath(router.asPath, { substep: currentSubStep + 1 }))
    } else {
      pushToDataLayer("Start", { move: "Start" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return (
    <Loader isLoading={hasLocationParameter()}>
      <Layout withAddress={false}>
        <Seo locale={lang} />
        <ContentContainer
          css={(theme) => ({
            [theme.mqs.tablet]: {
              paddingTop: 90,
            },
          })}
        >
          <AddressAutocompletePageContent
            onSelect={(address) => {
              setAddress(address)

              if (isAddressComplete(address)) {
                trackServerEvent("flowStartRedirect", { data: { addressComplete: true, source: "autocompleteFlow" } })

                if (shouldGoToHeyFlow(lang as Locale)) {
                  goToHeyFlow(address)
                } else {
                  router.push(compilePath(router.asPath, { step: 2, substep: 1 }))
                }
              } else {
                trackServerEvent("flowStartRedirect", { data: { addressComplete: false, source: "autocompleteFlow" } })
                router.push(compilePath(router.asPath, { step: 1, substep: 2 }))
              }
              emitSnowplowEvent({
                schema: eventSchema.flowStartRedirect,
                data: { addressComplete: isAddressComplete(address), source: "autocompleteFlow" },
              })

              pushToDataLayer("HomeAddressSubmittedInService")
            }}
          />
        </ContentContainer>
      </Layout>
    </Loader>
  )
}

export type PageInitialProps = {
  isSurveyLandingPage: true
  generatedOnServer: boolean
}

AddressAutocompletePage.getInitialProps = async () => {
  const generatedOnServer = typeof window === "undefined"
  return { generatedOnServer }
}

export default AddressAutocompletePage
